var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{
    night: _vm.grimoire.isNight,
    static: _vm.grimoire.isStatic
  },style:({
    backgroundImage: _vm.grimoire.background
      ? ("url('" + (_vm.grimoire.background) + "')")
      : ''
  }),attrs:{"id":"app","tabindex":"-1"},on:{"keyup":_vm.keyup}},[(_vm.grimoire.background && _vm.grimoire.background.match(/\.(mp4|webm)$/i))?_c('video',{attrs:{"id":"background","src":_vm.grimoire.background,"autoplay":"","loop":""}}):_vm._e(),_c('div',{staticClass:"backdrop"}),_c('transition',{attrs:{"name":"blur"}},[(!_vm.players.length)?_c('Intro'):_vm._e(),(_vm.players.length && !_vm.session.nomination)?_c('TownInfo'):_vm._e(),(_vm.session.nomination)?_c('Vote'):_vm._e()],1),_c('TownSquare'),_c('Menu',{ref:"menu"}),_c('EditionModal'),_c('FabledModal'),_c('RolesModal'),_c('ReferenceModal'),_c('NightOrderModal'),_c('VoteHistoryModal'),_c('GameStateModal'),_c('Gradients'),_c('span',{attrs:{"id":"version"}},[_vm._v("v"+_vm._s(_vm.version))])],1)}
var staticRenderFns = []

export { render, staticRenderFns }